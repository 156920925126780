@import '@angular/cdk/overlay-prebuilt.css';
@import 'ngx-toastr/toastr';
@import 'mixins';

:root {
  --color-01: #f7ac44;
  --color-02: #ececeb;
  --color-03: #ffe0b2;
  --color-04: #ffcc80;
  --color-05: #363734;
  --color-06: #8a8e91;
  --color-07: #bebebe;
  --color-08: #3a3d4f;
  --color-09: #fb8c00;
  --color-10: #ffd180;
  --color-11: #3a3b450d;
  --color-12: #24252f;
  --color-13: #25424e;
  --color-14: #ffffff;
  font-size: 16px;
}

html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

button {
  cursor: pointer;
}

.btn-avancar {
  background-color: #216e2a !important;
  color: white !important;
  border-radius: 10px;
  font-family: Roboto;
  font-weight: 700;
  font-size: 1rem;
}

.btn-avancar:disabled {
  background-color: #777777 !important;
  cursor: not-allowed !important; /* O cursor será mostrado como "não permitido" quando o botão estiver desabilitado */
}

.obrigatorio {
  color: #b91c1c !important;
}

.modal {
  &_h1 {
    font-size: 16px;
    line-height: 20px;
    color: #25424e;
    margin: 40px 0;
  }
}

.mat_option {
  width: 92% !important;
}

.c-app-form {
  display: flex;
  flex-direction: column;
  // height: 100%;

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_label {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.25rem;
  }

  &_descricao {
    font-size: 1rem;
    min-width: 16rem;
    width: 50%;
    margin-bottom: 1rem;
    color: #25424e;

    @include mq(lg) {
      width: 100%;
    }
  }

  &_field {
    min-width: 16rem;
    width: 50%;
    border-radius: 20px;

    @include mq(lg) {
      width: 100%;
    }
  }
}

.c-app-tabela {
  &_cabecalho {
    font-size: 1rem !important;
    color: white !important;
    background: var(--color-13) !important;
  }
}

::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background: var(--color-06);
  border-radius: 0.4rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-07);
}

.mat-drawer-inner-container {
  overflow: hidden !important;
  -webkit-overflow-scrolling: unset !important;
}

.u-font-size-1rem {
  font-size: 1rem !important;
}

.u-font-weight-600 {
  font-weight: 600 !important;
}

.u-font-weight-700 {
  font-weight: 700 !important;
}

.u-margin-bottom-1_25rem {
  margin-bottom: 1.25rem !important;
}

.u-margin-right-0_75rem {
  margin-right: 0.75rem !important;
}

.u-resize-none {
  resize: none !important;
}

.u-width-100p {
  width: 100% !important;
}

.u-padding-1rem {
  padding: 1rem !important;
}

.is-titulo {
  margin-bottom: 1.25rem !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.c-menu-lateral_content {
  height: 100vh;
  background-color: white;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
// input[type='number'] {
//   height: 100%;
//   width: 100%;
//   appearance: none !important;
//   background-color: transparent !important;
//   border: none !important;
// }